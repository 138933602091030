import logo from '../assets/img/logo.JPG';
import schoolName from '../assets/img/schoolNameonly.png';
import { Outlet, Link } from "react-router-dom";

function Header() {
    return (
      <div >
          
<ul className="nav" style={{color:"#fff",alignItems:'center',backgroundColor:'#04309c'}}>
     <a classNameName="navbar-brand" href="/" style={{marginLeft:'15px'}}> <img src={logo} width="65" height="65" alt="logo" /></a>
     {/* <marquee> <a className="navbar-brand" href="#"><img src={schoolName} width="180" height="65" alt="logo" /></a></marquee> */}
      <li className="nav-item">
    {/* <a className="nav-link active" href="#" style={{color:'#fff',paddingLeft:'18rem'}}></a> */}
    <marquee scrollamount="2" scrolldelay="1"> <a className="navbar-brand" href="#" style={{color:'#fff',paddingLeft:'0rem'}}><img src={schoolName} width="180" height="65" alt="logo" /></a></marquee>
  </li>
  <li className="nav-item">
    {/* <a className="nav-link active" href="#" style={{color:'#fff'}}>Home</a> */}
    <Link to={`/`}><a className="nav-link" href="#" style={{color:'#fff'}}>Home</a></Link>
  </li>
  <li className="nav-item">
  <Link to={`/AboutUS`}>  <a className="nav-link" href="#" style={{color:'#fff'}}>About us</a></Link>
  </li>
  <li className="nav-item">
  <Link to={`/AdmissionNotes`}> <a className="nav-link" href="#" style={{color:'#fff'}}>Admission criteria</a></Link>
  </li>
  <li className="nav-item">
  <Link to={`/princialNotes`}> <a className="nav-link" href="#" style={{color:'#fff'}}>Principal’s Desk</a></Link>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" style={{color:'#fff'}}>Facilities</a>
    <div className="dropdown-menu">
    
    <Link to={`/sports`}> <a className="dropdown-item" href="#">Sports</a></Link>
    <Link to={`/curricular`}> <a className="dropdown-item" href="#">Co curricular</a></Link>
    <Link to={`/houseSystem`}><a className="dropdown-item" href="#">House system</a></Link>
      {/* <div className="dropdown-divider"></div>
      <a className="dropdown-item" href="#">Separated link</a> */}
    </div>
  </li>
  <li className="nav-item">
  <Link to={`/gallery`}><a className="nav-link" href="#" style={{color:'#fff'}}>Gallery</a></Link>
    
  </li>
  <li className="nav-item">
  <Link to={`/mandatory`}><a className="nav-link" href="#" style={{color:'#fff'}}>Mandatory Disclosure</a></Link>
    
  </li>
  {/* <li className="nav-item">
    <a className="nav-link disabled" href="#">Disabled</a>
  </li> */}
</ul>
      </div>
    );
  }
  
  export default Header;