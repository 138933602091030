
import Header from '../home/Header';
import Footer from '../home/Footer';
import "./princ.css"
import bgImage from '../assets/banner/bgimage1.jpeg'

function Principal() {
    return (
        <div className="App" style={{backgroundColor:'rgba(249, 177, 177, 0.62)'}}>
            <Header />
            {/* <img src={bgImage} loading="eager" alt="Image" 
            style={{width:'100%',height:'150%',opacity:'0.4',display:'block',position:'absolute'}}
            ></img> */}
            <div class="c1 doc-content">
            <p class="c2">
            <span class="c0 c3">Dear Parents, staff, Students and well wishers...</span>
            </p><p class="c3 c4">
            <span class="c0">&nbsp;</span></p>
            <p class="c4 c3">
            <span class="c0">&nbsp;</span></p>
            <p class="c2"><span class="c0 c3">As we embark on a new chapter at St. Joseph Public School, it is with great joy and anticipation that I extend my warmest greetings to each one of you.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span>
            </p><p class="c4 c3"><span class="c0">&nbsp;</span></p>
            <p class="c2"><span class="c0 c3">The past academic year has been filled with challenges and triumphs, and I am immensely proud of the resilience and determination demonstrated by our entire school community. Together, we have overcome obstacles and embraced opportunities for growth and learning.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p>

            <p class="c2"><span class="c0 c3">As we look ahead to the upcoming academic year, I am filled with optimism and excitement for what lies ahead. With the unwavering support of our dedicated staff, the enthusiasm of our talented students, and the trust of our supportive parents, I am confident that we will continue to achieve excellence in all our endeavors.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c2"><span class="c0 c3">I extend my heartfelt congratulations to our graduating students, who have reached this significant milestone in their academic journey. Your hard work, perseverance, and dedication have paid off, and I have no doubt that you will go on to accomplish great things in the future.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c4 c3"></p><p class="c2"><span class="c0 c3">To our returning students, I encourage you to approach the new year with curiosity, enthusiasm, and a commitment to excellence. Embrace every opportunity for growth and learning, and remember that your potential is limitless.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c4 c3"></p><p class="c2"><span class="c0 c3">To our esteemed parents, I extend my sincere gratitude for your continued trust and support. Your partnership is invaluable, and together, we will continue to nurture and inspire the next generation of leaders and innovators.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c4 c3"></p><p class="c2"><span class="c0 c3">As we prepare to embark on this new academic year, let us do so with a sense of unity, purpose, and optimism. May it be a year filled with learning, growth, and success for each and every member of our school community.</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c4 c3"></p><p class="c2"><span class="c0 c3">Wishing you all the best for the journey ahead.</span></p><p class="c4 c3"><span class="c0">&nbsp;</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c2"><span class="c0 c3">Warm regards,</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c4 c3"></p><p class="c2"><span class="c0 c3">Fr Jithin Thomas</span></p>
            <p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c2"><span class="c0 c3">Principal</span></p><p class="c4 c3"><span class="c0">&nbsp;</span></p><p class="c2"><span class="c0 c3">St. Joseph Public School</span></p><p class="c6"><span class="c0 c3"></span></p><p class="c2 c7"><span class="c5"></span></p></div>
                <Footer />
            </div>
            );
}

            export default Principal;