
import './tablestyle.css';
import TableData from './table.json'
import Document from './docJS/doc1';

function Table() {
  return (
    <div style={{backgroundColor:'#f6f6da',paddingTop:'10px', paddingBottom:'10px'}}>

     
 <div className=".container-fluid " style={{marginLeft:'10%', marginRight:'10%',marginTop:'2%'}}>
     <table class="table table-bordered" >
  <thead>
    <tr style={{textAlign:'center'}}>
      <th scope="col" className="from" >S.NO</th>
      <th scope="col" className="subject" >Document Details</th>
      <th scope="col" className="date">Download Link</th>
      
    </tr>
  </thead>
  <tbody>
  {TableData.map((row, index) =><tr>
      <th scope="row" style={{textAlign:'center'}}>{row.sNo}</th>
      <td>{row.name}</td>
      <td style={{textAlign:'center'}}><a classNameName="navbar-brand" href={row.url} >{row.btnText}</a></td>
      
    </tr>)}
    {/* <tr>
      <th scope="row">2</th>
      <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE</td>
      <td><a classNameName="navbar-brand" href="/DOClanddoc.html" >Download</a></td>
      
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>COPY OF NO OBJECTION CERTIFICATE {"(NOC)"} ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT</td>
      <td><a classNameName="navbar-brand" href="/DOCNOC.html" >Download</a></td>
    </tr>
    <tr>
      <th scope="row">4</th>
      <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009</td>
      <td><a classNameName="navbar-brand" href="/DOCRecognition.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">5</th>
      <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</td>
      <td><a classNameName="navbar-brand" href="/DOCBuildingStability.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">6</th>
      <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</td>
      <td><a classNameName="navbar-brand" href="/DOCFireSafty.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">7</th>
      <td>COPY OF THE SELF CERTIFICATION SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION</td>
      <td><a classNameName="navbar-brand" href="/DOCDEO.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">8</th>
      <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
      <td><a classNameName="navbar-brand" href="/DOCSanitoryCert.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">9</th>
      <td>FEE STRUCTURE OF THE SCHOOL</td>
      <td><a classNameName="navbar-brand" href="/DOCFees.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">10</th>
      <td>ANNUAL ACADEMIC CALENDER</td>
      <td><a classNameName="navbar-brand" href="/DOCcbsediary.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">11</th>
      <td>LIST OF SCHOOL MANAGEMENT COMMITTEE {"(SMC)"}</td>
      <td><a classNameName="navbar-brand" href="/DOCSMC.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">12</th>
      <td>LIST OF PARENTS TEACHERS ASSOCIATION {"(PTA)"} MEMBERS</td>
      <td><a classNameName="navbar-brand" href="/DOCPTA.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">13</th>
      <td>LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY</td>
      <td><a classNameName="navbar-brand" href="/DOCxResult.html" >Download</a></td>
      </tr>
    <tr>
      <th scope="row">14</th>
      <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
      <td><a classNameName="navbar-brand" href="https://www.youtube.com/watch?v=1iv3jqDLmco" >Play</a></td>
      </tr>
    <tr>
      <th scope="row">15</th>
      <td>SARAS</td>
      <td><a classNameName="navbar-brand" href="/DOCSaras.html" >Download</a></td>
    </tr> */}
  </tbody>
</table></div>
                                    {/* <hr class="divider_dashed">									
                                </div>
                            </article>  */}
            
                          
    </div>
  );
}

export default Table;

                               