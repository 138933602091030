import './mapstyle.css'

function MapDiv() {
  return (
    <div >
        <div className="responsive-map">
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9702.970768943884!2d77.25375790528132!3d9.005970042835552!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0681a85bfa91d3%3A0xe5edefcb7e99a80!2sSt.%20Joseph%20Public%20School!5e0!3m2!1sen!2sin!4v1704281771892!5m2!1sen!2sin" width="600" height="400" style={{border:"0"}} allowfullscreen="" title='st school' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
  
</div>
    
  );
}

export default MapDiv;