import Header from './Header';
import Slider from './Slider';

function ErrorPG() {
  return (
    <div >
        <div className="container text-center" id="error">

{/* <svg height="100" width="100">
  <circle cx="50" cy="50" r="31" stroke="#679b08" stroke-width="9.5" fill="none" />
  <circle cx="50" cy="50" r="6" stroke="#679b08" stroke-width="1" fill="#679b08" />
  <line x1="50" y1="50" x2="35" y2="50" style={{stroke:'#679b08',strokeWidth:'6'}} />
  <line x1="65" y1="35" x2="50" y2="50" style={{stroke:'#679b08',strokeWidth:'6'}} />
  <path d="M59 65 L83 65 L75 87 Z" fill="#679b08" />
  <rect width="20" height="9" x="70" y="56" style={{fill:'#eee',strokeWidth:'0'}} />
</svg> */}
<br/>

<div className="row">
  <div className="col-md-12">
    <div className="main-icon text-success"><span className="uxicon uxicon-clock-refresh"></span></div>
    
  </div>
</div>

</div>
    </div>
  );
}

export default ErrorPG;