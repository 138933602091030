
import './gallerystyle.css'
import lib from '../assets/img/gallery/lib.jpeg';
import lab from '../assets/img/gallery/lab.jpeg';
import inPlay from '../assets/img/gallery/IndoorPlayground.jpeg';
import musicPlay from '../assets/img/gallery/musicalPlayground.jpeg';
import bus from '../assets/img/gallery/busView.jpeg';
import yoga from '../assets/img/gallery/yoga.jpeg';
import silambam from '../assets/img/gallery/silambam.jpeg';

function CoreGallery() {
  return (
    
       
        <div class="container gallery-container">
{/* 
<h1>School Image</h1>

<p class="page-description text-center">Fluid Layout With Overlay Effect</p> */}

<div class="tz-gallery">

    <div class="row">

        <div class="col-sm-12 col-md-4">
            <a class="lightbox" href={lib}>
                <img src={lib} alt="Bridge" />
            </a>
        </div>
        <div class="col-sm-6 col-md-4">
        <a class="lightbox" href={lab}>
                <img src={lab} alt="Bridge" />
            </a>
        </div>
        <div class="col-sm-6 col-md-4">
        <a class="lightbox" href={inPlay}>
                <img src={inPlay} alt="Bridge" />
            </a>
        </div>
        <div class="col-sm-12 col-md-8">
        <a class="lightbox" href={musicPlay}>
                <img src={musicPlay} alt="Bridge" />
            </a>
        </div>
        <div class="col-sm-6 col-md-4">
        <a class="lightbox" href={bus}>
                <img src={bus} alt="Bridge" />
            </a>
        </div> 
        <div class="col-sm-6 col-md-4">
        <a class="lightbox" href={yoga}>
                <img src={yoga} alt="Bridge" />
            </a>
        </div>

    </div>

</div>

</div>
       
  );
}

export default CoreGallery;