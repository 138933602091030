
import Header from '../home/Header';
import Footer from '../home/Footer';
import logo from '../assets/img/logo.JPG';
import bgImage from '../assets/banner/bgimage1.jpeg'
function Principal() {
    return (
        <div style={{backgroundColor:'rgba(249, 177, 177, 0.62)'}}>
            {/* <div style={{backgroundImage:"url(//www.html.am/images/backgrounds/background-image-2.gif)"}}> */}
           
            <Header />
            {/* <img src={bgImage} loading="eager" alt="Image" 
            style={{width:'100%',height:'190%',opacity:'0.4',display:'block',position:'absolute'}}
            ></img> */}
            
            <div className="container my-5">
        <div className="text-center mb-5">
        <img src={logo} width="90" height="90" alt="logo" /> 
            {/* <p>St. Joseph Public School</p> */}
        </div>

        <section>
            
            <p style={{textAlign:'justify'}}>&emsp;&emsp;&emsp;&emsp;<strong>St. Joseph Public School</strong> is a secondary co-educational school established in 2013 recognized by the Directorate of Education Government of Delhi and affiliated to CBSE. It is managed by the MCBS Congregation whose members are involved in education at various levels, social work, caring for the destitute, rendering medical care, and offering spiritual and psychological nourishment to people worldwide.</p>
        </section>

        

        <section>
        <h2>School Motto : “EMPOWERING TO EXCEL”</h2>
            {/* <h2>The Preamble</h2>
            <p>We the people of India having solemnly resolved to constitute India into a SOVEREIGN SOCIALIST SECULAR DEMOCRATIC REPUBLIC and to secure to all its citizens:</p>
            <ul>
                <li><strong>JUSTICE</strong> - social, economic and political</li>
                <li><strong>LIBERTY</strong> - of thought, expression, belief, faith and worship</li>
                <li><strong>EQUALITY</strong> - of status and of opportunity; and to promote among them all</li>
                <li><strong>FRATERNITY</strong> - assuring the dignity of the individual and the unity and integrity of the Nation</li>
            </ul> */}
        </section>

        <section>
            <h2>Our Aim</h2>
            <ul>
                <li>Aspire to inspire the young minds</li>
                <li>To empower tomorrow</li>
                <li>Inspire for better living by instilling values</li>
                <li>Motivate lives to explore life goals</li>
            </ul>
        </section>

        <section>
            <h2>Vision</h2>
            <p>The vision is for every student graduating from this school to have grown in their sense of the divine, respect for human life, compassion for the poor, concern for justice, awareness of oppressive social structures, and commitment to help build a better human society, becoming responsible citizens.</p>
        </section>

        <section>
            <h2>We Provide</h2>
            <ul>
                <li>Child-friendly Campus</li>
                <li>Child-centred classrooms</li>
                <li>Wide range of sports facilities</li>
                <li>Spacious playgrounds to engage in ‘Fitness Pursuits’</li>
                <li>Lush lawns and a verdant environment to give children freedom to explore themselves with nature</li>
                <li>Sand pit/swings and Play Hill specially designed for tiny tots to explore and engage in free play</li>
                <li>Audio visual room</li>
                <li>Library</li>
                <li>Wellness Room</li>
                <li>Laboratories</li>
            </ul>
        </section>

        <section>
            <h2>We Give</h2>
            <ul>
                <li>Value-driven Holistic Education</li>
                <li>Contemporary development-based curriculum by inculcating best practices of Gardener’s theory of Multiple Intelligence and Piaget’s theory of Cognitive Development</li>
                <li>Pre-Primary Montessori programme</li>
                <li>Learning in context</li>
                <li>Experiential & Project-Based Learning</li>
                <li>Plan for skill development and individualized educational needs and aspirations</li>
                <li>Learning beyond boundaries (field trips, excursions, visits, Heritage walks)</li>
                <li>Meditation and Reflection</li>
            </ul>
        </section>

        <section>
            <h2>Experienced and Qualified Facilitators</h2>
            <p>The facilitators are well trained, qualified, and experienced. Continuous Professional Development Programmes for facilitators are an integral part of innovation in teaching and learning dynamics in the institution.</p>
        </section>

        <section>
            <h2>Effective Parental Engagement Programme</h2>
            <ul>
                <li>Frequent Parent Teacher Meetings (PTMs)</li>
                <li>Regular workshops for parents</li>
                <li>Engaging parents in their children’s learning through interactive activity sessions</li>
            </ul>
        </section>
    </div>
            <Footer /></div>
            // </div>
            );
}

            export default Principal;